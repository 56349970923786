import React from "react";

const SettingsView = () => {
  return (
    <div className="settings-view view">
      <p>Settings View</p>
    </div>
  );
};

export default SettingsView;
