import React from "react";
import LandingPage from "../components/LandingPage/LandingPage";

const LandingPageView = () => {
  return (
    <div>
      <LandingPage />
    </div>
  );
};

export default LandingPageView;
